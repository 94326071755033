var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { align: "center" } }, [
    _c("br"),
    _vm._m(0),
    _c("br"),
    _c("br"),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.is_loading,
            expression: "!is_loading",
          },
        ],
        staticClass:
          "text-center justify-center q-pa-md q-gutter-sm fixed-center",
        staticStyle: { "z-index": "1000" },
      },
      [_c("q-spinner-hourglass", { attrs: { size: "4em" } })],
      1
    ),
    _c("div", { staticClass: "row justify-center" }, [
      _c(
        "div",
        { staticClass: "col-12 col-md-4", attrs: { align: "left" } },
        [
          _c(
            "q-list",
            { attrs: { separator: "" } },
            [
              _c(
                "q-item",
                [
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Area:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.quotazione.area)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Prodotto:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.quotazione.id_prodotto)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "q-item",
                [
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Numero preventivo:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.quotazione.guid_preventivo)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "q-item",
                [
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Descrizione preventivo:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.quotazione.nominativo)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Stato della quotazione:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.getStato)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "q-item",
                [
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Unità Operativa:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.quotazione.unita_operativa)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Operatore:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.quotazione.operatore)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-12 col-md-6 q-px-md", attrs: { align: "left" } },
        [
          _c("h5", { staticClass: "q-mt-sm q-mb-sm q-pt-sm" }, [
            _vm._v("Documenti gia allegati"),
          ]),
          _vm.elenco_documenti.length === 0
            ? _c("div", [_vm._v("Nessun documento allegato")])
            : _vm._e(),
          _vm.elenco_documenti.length > 0
            ? _c(
                "div",
                [
                  _c(
                    "q-list",
                    { attrs: { bordered: "", separator: "" } },
                    _vm._l(_vm.elenco_documenti, function (documento, index) {
                      return _c(
                        "q-item",
                        {
                          directives: [{ name: "ripple", rawName: "v-ripple" }],
                          key: index,
                          attrs: { tag: "label" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.onScaricaDocumento(documento)
                            },
                          },
                        },
                        [
                          _c(
                            "q-item-section",
                            [
                              _c("q-item-label", [
                                _vm._v("Nome del documento"),
                              ]),
                              _c("q-item-label", { attrs: { caption: "" } }, [
                                _vm._v(_vm._s(documento.label)),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "q-item-section",
                            [
                              _c("q-item-label", [
                                _vm._v("Dimensione del file"),
                              ]),
                              _c("q-item-label", { attrs: { caption: "" } }, [
                                _vm._v(_vm._s(documento.file_size) + " bytes"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "q-item-section",
                            [
                              _c("q-item-label", [_vm._v("Info documento")]),
                              _c("q-item-label", { attrs: { caption: "" } }, [
                                _vm._v(
                                  _vm._s(
                                    documento.value[0].descrizione_documento
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "q-item-section",
                            { attrs: { avatar: "" } },
                            [
                              _c("q-avatar", {
                                attrs: {
                                  color: "orange-3",
                                  "text-color": "black",
                                  icon: "mdi-download",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._m(1),
      _c(
        "div",
        { staticClass: "col-12 col-md-6 q-px-md", attrs: { align: "center" } },
        [
          _c("q-uploader", {
            ref: "UploadObject",
            staticStyle: {
              "max-width": "800px",
              width: "100%",
              "min-height": "300px",
              "box-shadow":
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            },
            attrs: {
              url: "https://localhost:1000",
              color: "teal",
              label: "Trascina QUI i documenti da inviare",
              multiple: "",
              "hide-upload-btn": "",
              filter: _vm.checkFileType,
            },
            on: {
              rejected: _vm.onRejected,
              added: _vm.onAggiungiFileAllegati,
              removed: _vm.onRimuoviFileAllegati,
              uploading: _vm.onUploading,
            },
          }),
        ],
        1
      ),
    ]),
    _c("br"),
    _c("br"),
    _c("hr"),
    _c("div", { staticClass: "row", attrs: { align: "center" } }, [
      _c(
        "div",
        { staticClass: "col-12 col-md-6", attrs: { align: "center" } },
        [
          _c("QQButton", {
            attrs: {
              label: "INDIETRO",
              color: "blue-grey",
              icon: "undo",
              size: "md",
              disable: !_vm.is_loading,
            },
            nativeOn: {
              click: function ($event) {
                return _vm.onIndietroClicked.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-12 col-md-6", attrs: { align: "center" } },
        [
          _c("QQButton", {
            attrs: {
              label: "ALLEGA",
              color: "blue-grey",
              icon: "mdi-content-save-outline",
              size: "md",
              icon_align: "right",
              disable: !_vm.is_loading,
            },
            nativeOn: {
              click: function ($event) {
                return _vm.onUploading.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
    ]),
    _c("br"),
    _c("br"),
    _c("br"),
    _c("br"),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { align: "center" } }, [
      _c("span", { staticClass: "text-h4" }, [
        _vm._v("Allega documenti alla Quotazione"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-12 col-md-12 q-px-md", attrs: { align: "center" } },
      [_c("hr"), _c("br")]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }